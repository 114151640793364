import React from 'react'
import './Home.css'
import Header from './Header.jsx'
import PalmTree from '../../images/palmTree1.png'
function Home() {
  function handleScheduleClick() {
    // Handle the logic for the Schedule Appointment button
  }

  function handleMeetKarrieClick() {
    // Handle the logic for the Meet Karrie button
  }
  return (
    <div>
      <Header />
      <div className="main">
        <div className="d-flex flex-column align-items-center">
          <img src={PalmTree} alt="Palm Tree" className="palm-image-one"></img>
          <div className="d-flex flex-row justify-content-center mt-4">
            <button className="custom-button schedule mx-2" onClick={handleScheduleClick}>Schedule Appointment</button>
            <button className="custom-button meet-karrie mx-2" onClick={handleMeetKarrieClick}>Meet Karrie</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home