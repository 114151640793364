import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import {RxHamburgerMenu} from 'react-icons/rx'
import './bm.css'

function HamMenu() {
  return (
    <div>
        <Menu customBurgerIcon={ <RxHamburgerMenu/> } >
                <a id="Welcome" className="menu-item" href="/">
                    <div className="py-2 border-top border-bottom border-dark">
                        <h2 className="fw-normal menu-item-heading">Menu</h2>
                    </div>
                </a>
                <a id="home" className="menu-item" href="/">
                    <div className="border-bottom border-dark">
                        <h2 className="fw-normal menu-item-heading">Home</h2>
                    </div>
                </a>
                <a id="appointments" className="menu-item" href="/appointments">
                    <div className="border-bottom border-dark">
                        <h2 className="fw-normal menu-item-heading">Appointments</h2>
                    </div>
                </a>
                <a id="about" className="menu-item" href="/about">
                    <div className="border-bottom border-dark">
                        <h2 className="fw-normal menu-item-heading">About Me</h2>
                    </div>
                </a>

            </Menu>
    </div>
  )
}

export default HamMenu