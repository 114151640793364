import './Home.css'
import HamMenu from '../../components/HamMenu'

function Header() {

    return (
        <div className='header'>
            <HamMenu />
            <div>
                <h2 className="title">Oasis Massage</h2>
            </div>
        </div>
    );
}

export default Header